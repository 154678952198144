var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-page columns"},[_c('div',{staticClass:"column"},[_c('section',{staticClass:"hero is-fullheight"},[_c('div',{staticClass:"hero-body"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"mb-5 has-text-centered"},[_c('a',{attrs:{"target":"_blank","href":_vm.portalLook
                  ? ("https://" + (_vm.portalLook.slug) + ".zensimu.com/")
                  : _vm.zensimuSite
                  ? "https://zensimu.com/"
                  : "https://beergameapp.com/"}},[_c('main-logo',{staticClass:"mb-20",attrs:{"show-title":"true","custo-look":_vm.custoLook}})],1)]),_c('h2',{staticClass:"title is-2 has-text-centered"},[_vm._v(" Log In ")]),_c('b-notification',{attrs:{"active":_vm.showError,"type":"is-danger"},on:{"close":_vm.closeError}},[_vm._v(_vm._s(_vm.showError ? _vm.error.message : ""))]),_c('form',{attrs:{"novalidate":""},on:{"submit":_vm.submitLogin}},[_c('b-field',{attrs:{"label":"Email","type":{ 'is-danger': _vm.$v.email.$error },"message":!_vm.$v.email.$error
                  ? ''
                  : !_vm.$v.email.required
                  ? 'Email is required'
                  : 'Wrong email format'}},[_c('b-input',{ref:"focusinput",attrs:{"type":"email","disabled":_vm.loading},model:{value:(_vm.$v.email.$model),callback:function ($$v) {_vm.$set(_vm.$v.email, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.email.$model"}})],1),(!_vm.resetMode)?_c('b-field',{attrs:{"label":"Password","type":{ 'is-danger': _vm.$v.password.$error },"message":!_vm.$v.password.$error
                  ? ''
                  : !_vm.$v.password.required
                  ? 'Password is required'
                  : 'Password must have at least 6 characters'}},[_c('b-input',{attrs:{"type":"password","password-reveal":"","disabled":_vm.loading},model:{value:(_vm.$v.password.$model),callback:function ($$v) {_vm.$set(_vm.$v.password, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.password.$model"}})],1):_vm._e(),(_vm.warningStudent)?_c('b-message',{attrs:{"type":"is-danger","has-icon":""}},[_vm._v(" Please Note The Beergame App is designed to be used by teachers ony. "),_c('br'),_vm._v("If you are a student, please ask your referent teacher to contact us at "),_c('em',[_vm._v("hello@zensimu.com")]),_vm._v(". ")]):_vm._e(),_c('b-field',{staticClass:"has-text-centered mt-4"},[(!_vm.resetValidated)?_c('button',{staticClass:"button is-primary is-rounded is-large btn-login",attrs:{"type":"submit","disabled":_vm.loading}},[(!_vm.resetMode)?_c('span',[_vm._v("Log in")]):_vm._e(),(_vm.resetMode)?_c('span',[_vm._v("Send password reset link")]):_vm._e()]):_vm._e()]),(_vm.resetMode && _vm.resetValidated)?_c('p',{staticClass:"has-text-success"},[_vm._v(" Password reset link sent "),_c('b-icon',{attrs:{"icon":"check","size":"is-small"}})],1):_vm._e(),_c('a',{on:{"click":_vm.toggleResetMode}},[(!_vm.resetMode)?_c('p',[_vm._v("Forgot your password ?")]):_vm._e(),(_vm.resetMode)?_c('p',[_c('b-icon',{attrs:{"icon":"arrow-left","size":"is-small"}}),_vm._v(" Back to login ")],1):_vm._e()]),_c('div',{staticClass:"has-text-centered mt-5"},[_c('router-link',{attrs:{"to":"/signup"}},[_vm._v(" Not a member yet ? Sign up ")])],1)],1)],1)])])]),_c('div',{staticClass:"column is-hidden-touch",style:({
      'background-color': _vm.custoLook.bckColor,
      color: _vm.custoLook.textColor
    })},[_c('section',{staticClass:"hero is-fullheight"},[_c('div',{staticClass:"hero-body"},[_c('testimonials')],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }