<template>
  <div class="signup-page">
    <div class="columns">
      <div class="column">
        <section class="hero is-fullheight">
          <div class="hero-body">
            <div class="container">
              <div class="has-text-centered mb-4">
                <a
                  target="_blank"
                  :href="
                    portalLook
                      ? `https://${portalLook.slug}.zensimu.com/`
                      : zensimuSite
                      ? `https://zensimu.com/`
                      : `https://beergameapp.com/`
                  "
                >
                  <main-logo
                    class="mb-20"
                    show-title="true"
                    :custo-look="custoLook"
                    noclick="true"
                  ></main-logo>
                </a>
              </div>
              <h2 class="title is-2 has-text-centered mb-3">
                {{
                  signupStep === 0
                    ? "Create an account"
                    : "Let's set up your account"
                }}
                <h3
                  class="subtitle is-5 is-italic mt-2"
                  v-if="signupStep === 1"
                >
                  {{ email }}
                </h3>
              </h2>
              <b-notification
                :active="showError"
                @close="closeError"
                type="is-danger"
                >{{ showError ? error.message : "" }}</b-notification
              >
              <form @submit="submitSignUp" novalidate class="mt-6">
                <!-- <div class="is-divider"></div> -->
                <div v-if="signupStep === 0">
                  <b-field
                    label="Work Email*"
                    :type="{ 'is-danger': $v.email.$error }"
                    :message="
                      !$v.email.$error
                        ? ''
                        : !$v.email.required
                        ? 'Email is required'
                        : 'Wrong email format'
                    "
                  >
                    <b-input
                      ref="emailinput"
                      type="email"
                      v-model="$v.email.$model"
                      :disabled="loading"
                    ></b-input>
                  </b-field>
                  <b-field
                    label="Password*"
                    :type="{ 'is-danger': $v.password.$error }"
                    :message="
                      !$v.password.$error
                        ? ''
                        : !$v.password.required
                        ? 'Password is required'
                        : 'Password must have at least 6 characters'
                    "
                  >
                    <b-input
                      type="password"
                      v-model="$v.password.$model"
                      password-reveal
                      :disabled="loading"
                    ></b-input>
                  </b-field>
                </div>
                <div v-if="signupStep === 1">
                  <b-field grouped grouped-multilined class="mb-0">
                    <b-field
                      label="First Name*"
                      :message="
                        !$v.firstname.$error ? '' : 'First name is required'
                      "
                      :type="{ 'is-danger': $v.firstname.$error }"
                      expanded
                    >
                      <b-input
                        ref="firstnameinput"
                        type="text"
                        v-model.trim="$v.firstname.$model"
                        :disabled="loading"
                      ></b-input>
                    </b-field>
                    <b-field
                      label="Last Name*"
                      :message="
                        !$v.lastname.$error ? '' : 'Last name is required'
                      "
                      :type="{ 'is-danger': $v.lastname.$error }"
                      expanded
                    >
                      <b-input
                        type="text"
                        v-model.trim="$v.lastname.$model"
                        :disabled="loading"
                      ></b-input>
                    </b-field>
                  </b-field>
                  <b-field label="I am a.. *" v-if="!portalLook">
                    <b-field expanded>
                      <b-radio-button
                        expanded
                        v-model="profileType"
                        native-value="education"
                      >
                        <b-icon icon="presentation" size="is-small"></b-icon>
                        <span>Professor</span>
                      </b-radio-button>
                      <b-radio-button
                        expanded
                        v-model="profileType"
                        native-value="student"
                      >
                        <b-icon icon="school" size="is-small"></b-icon>
                        <span>Student</span>
                      </b-radio-button>
                      <b-radio-button
                        expanded
                        v-model="profileType"
                        native-value="business"
                      >
                        <b-icon icon="briefcase" size="is-small"></b-icon>
                        <span>Professional</span>
                      </b-radio-button>
                    </b-field>
                  </b-field>
                  <b-field
                    class="pt-3"
                    label="How did you hear about us ?"
                    v-if="!portalLook"
                  >
                    <b-field grouped>
                      <b-select
                        expanded
                        placeholder="Select a channel"
                        v-model="attributionSource"
                      >
                        <option
                          v-for="choice in attributionChoices"
                          :key="choice.val"
                          :value="choice.val"
                          >{{ choice.text }}</option
                        >
                      </b-select>
                      <b-input
                        placeholder="Please precise"
                        grouped
                        v-if="attributionSource === 'other'"
                        expanded
                        v-model="attributionOtherText"
                      >
                      </b-input>
                    </b-field>
                  </b-field>
                </div>
                <div class="buttons is-centered mt-6">
                  <b-button
                    v-if="signupStep === 1"
                    @click="signupStep = 0"
                    type="is-primary"
                    icon-left="arrow-left"
                    rounded
                    outlined
                  >
                    Back
                  </b-button>
                  <button
                    type="submit"
                    class="button is-primary is-large is-rounded btn-signup"
                    :disabled="loading"
                  >
                    {{ signupStep === 0 ? "Sign up" : "Confirm Sign up" }}
                    <b-icon
                      class="ml-1"
                      v-if="signupStep === 0"
                      icon="arrow-right"
                      size="is-small"
                    ></b-icon>
                  </button>
                </div>
                <div class="has-text-centered pt-3">
                  <em class="is-size-6">
                    By signing up you accept our
                    <a
                      href="https://zensimu.com/terms"
                      class="has-text-primary"
                      target="_blank"
                      rel="noreferrer"
                      >terms of use
                    </a>
                    and
                    <a
                      href="https://zensimu.com/privacy"
                      class="has-text-primary"
                      target="_blank"
                      rel="noreferrer"
                      >privacy policy
                    </a>
                  </em>
                  <br />
                  <br />
                  <router-link to="/login" class="mt-5">
                    Already have an account ?
                  </router-link>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
      <div
        class="column is-hidden-touch"
        :style="{
          'background-color': custoLook.bckColor,
          color: custoLook.textColor
        }"
      >
        <section class="hero is-fullheight">
          <div class="hero-body">
            <div class="has-text-centered">
              <!-- <ul class="has-text-left pl-6" v-if="!portalLook">
                <li class="is-size-5">
                  <b-icon
                    icon="plus-circle"
                    size="is-small"
                    class="mr-1"
                  ></b-icon>
                  Create customized games
                </li>
                <li class="is-size-5">
                  <b-icon icon="poll" size="is-small" class="mr-1"></b-icon>
                  Discover analytics, Compare simulations
                </li>
                <li class="is-size-5">
                  <b-icon icon="star" size="is-small" class="mr-1"></b-icon>
                  Upgrade for the full multiplayer experience
                </li>
              </ul> -->
            </div>
            <testimonials></testimonials>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Testimonials from "@/components/marketing/Testimonials.vue";
import MainLogo from "@/components/MainLogo.vue";
import { required, minLength, email } from "vuelidate/lib/validators";
const mustNotContainPlus = value => value.indexOf("+") < 0;

export default {
  name: "signup",
  components: { MainLogo, Testimonials },
  computed: {
    ...mapGetters([
      "loading",
      "error",
      "showError",
      "custoLook",
      "portalLook",
      "attributionChoices"
    ]),
    name() {
      return this.firstname + " " + this.lastname;
    }
  },
  mounted() {
    this.$refs.emailinput.focus();
    if (this.portalLook) {
      this.profileType = "business";
    }
  },
  methods: {
    submitSignUp(e) {
      e.preventDefault();
      if (this.signupStep === 0) {
        this.$v.email.$touch();
        this.$v.password.$touch();
        if (!this.$v.email.$invalid && !this.$v.password.$invalid) {
          this.signupStep = 1;
          setTimeout(() => {
            this.$refs.firstnameinput.focus();
          }, 5);
        }
      } else {
        this.$v.$touch();
        let dom = this.email.split("@").pop();
        if (this.portalLook && dom !== this.portalLook.emailDomain) {
          return this.$store.commit("setError", {
            message: "The email domain is invalid"
          });
        } else if (!this.$v.$invalid) {
          this.$store
            .dispatch("createAccount", {
              email: this.email,
              password: this.password,
              name: this.name,
              firstname: this.firstname,
              lastname: this.lastname,
              organization: this.organization,
              profileType: this.profileType,
              optinUpdates: this.optinUpdates,
              attributionSource: this.attributionSource,
              attributionOtherText: this.attributionOtherText
            })
            .catch(() => {
              this.signupStep = 0;
            });
        }
      }
    },
    closeError() {
      this.$store.commit("clearError");
    }
  },
  validations: {
    name: { required },
    firstname: { required },
    lastname: { required },
    email: {
      required,
      email,
      mustNotContainPlus
    },
    password: {
      required,
      minLength: minLength(6)
    }
  },
  data() {
    return {
      signupStep: 0,
      attributionSource: null,
      attributionOtherText: null,
      firstname: "",
      lastname: "",
      optinUpdates: true,
      organization: "",
      profileType: "education",
      email: "",
      password: ""
    };
  }
};
</script>

<style lang="scss">
.signup-page {
  .is-divider::after {
    background-color: $light;
  }
  .hero-body {
    padding-top: 1.5rem;
    .container {
      padding: 0 2em;
      @media (max-width: 767px) {
        padding: 0;
      }
    }
  }
  .hero-head {
    &.hero-body {
      padding-top: 0em;
    }
    h1 {
      margin-bottom: 0.5em;
    }
    li {
      margin-bottom: 0.3em;
    }
  }
}
</style>
