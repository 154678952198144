<template>
  <div class="home-wrapper pb-6">
    <section class="section is-mid has-text-centered columns is-centered">
      <main-globe :col="custoLook.mainColor"></main-globe>
      <div class="column is-two-thirds">
        <h1 class="title is-1 is-size-2-mobile mb-0">
          {{ custoLook.heroTitle ? custoLook.heroTitle : "Welcome!" }}
        </h1>
        <div class="mt-5 is-size-4" v-if="currentUser && currentUser.email">
          <p class="mb-2">
            Connected as :
            <b>{{ currentUser.firstname }} {{ currentUser.lastname }}</b>
          </p>
          <p>
            <b-button
              tag="router-link"
              to="/dashboard/games"
              size="is-medium"
              type="is-primary"
              rounded
            >
              Back to my Instructor Dashboard
            </b-button>
          </p>
        </div>
        <div
          class="mt-5 is-size-4"
          v-if="currentUser && currentUser.playingGame"
        >
          <p class="mb-2">
            Currently playing <b>{{ currentUser.playingGame.name }}</b> as
            <b>{{ currentUser.playingGame.roleName }}</b
            >.
          </p>
          <p>
            <b-button
              tag="router-link"
              :to="`/game/${currentUser.playingGame.id}/play`"
              size="is-medium"
              type="is-primary"
              rounded
            >
              Back to the game
            </b-button>
          </p>
        </div>
        <p class="mt-5">
          <a href="https://zensimu.com/" target="_blank" rel="noreferrer"
            >Go the marketing website
            <b-icon icon="arrow-right" size="is-small"></b-icon
          ></a>
        </p>
      </div>
    </section>
    <section
      class="section columns has-background-white is-multiline is-centered pb-6"
    >
      <div class="columns column is-four-fifths">
        <div class="column">
          <h1 class="title is-4 has-text-centered">
            Join a simulation
          </h1>
          <div class="box content is-size-5">
            <div class="px-5">
              <div
                v-if="!currentUser || (currentUser && !currentUser.playingGame)"
              >
                You don't need an account on the website to join and play a
                game.
                <br /><br />
                Follow the link received from your instructor and connect as one
                of the supply-chain roles.
              </div>
              <div v-if="currentUser && currentUser.playingGame">
                You are currently playing
                <router-link
                  class="has-text-success has-text-weight-bold"
                  :to="`/game/${currentUser.playingGame.id}`"
                  >{{ currentUser.playingGame.name }}</router-link
                >
                as
                <router-link
                  class="has-text-success has-text-weight-bold"
                  :to="`/game/${currentUser.playingGame.id}/play`"
                  >{{ currentUser.playingGame.roleName }}</router-link
                >. <br /><br />
                To join another game you first need to
                <a
                  @click="quitGame"
                  class="has-text-danger has-text-weight-bold"
                  >quit your current simulation</a
                >.
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <h1 class="title is-4 has-text-centered">
            Host a simulation
          </h1>
          <div class="box content is-size-5">
            <div class="px-5">
              <div v-if="currentUser && !currentUser.email">
                To create your own games as an instructor please
                <a
                  @click="quitGame"
                  class="has-text-danger has-text-weight-bold"
                  >quit your current simulation</a
                >
                then create an account on the website.
              </div>
              <div v-if="currentUser && currentUser.email">
                To create a new game, go to your Dashboard :
                <p class="has-text-centered mt-4">
                  <router-link
                    class="button is-info is-medium is-rounded"
                    to="/dashboard/create"
                    >Create Game</router-link
                  >
                </p>
              </div>
              <div v-if="!currentUser">
                Please connect to create and host supply-chain simulations :
                <p class="has-text-centered mt-4">
                  <router-link
                    class="button is-info is-medium is-rounded"
                    to="/login"
                    >Log In</router-link
                  >
                </p>
                <div v-if="!portalLook">
                  Don't have an account yet ? You can create one for free :
                  <p class="has-text-centered mt-4">
                    <router-link
                      class="button is-primary is-medium is-rounded"
                      to="/signup"
                      >Sign Up</router-link
                    >
                  </p>
                </div>
              </div>
              <div v-if="portalLook && portalLook.docsLink" class="mt-5">
                Discover best practices and material to help you get started :
                <p class="has-text-centered mt-4">
                  <a
                    class="button is-warning is-medium is-rounded"
                    :href="portalLook.docsLink"
                    target="_blank"
                    rel="noreferrer"
                    >Browse documentation</a
                  >
                </p>
              </div>
              <div v-if="!portalLook" class="mt-5">
                Discover best practices and material to help you get started :
                <p class="has-text-centered mt-4">
                  <a
                    class="button is-warning is-medium is-rounded"
                    href="https://zensimu.com/guide"
                    target="_blank"
                    rel="noreferrer"
                    >Read the Guide</a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import MainGlobe from "@/components/home/MainGlobe.vue";
import { mapGetters } from "vuex";

export default {
  name: "home",
  computed: {
    ...mapGetters(["currentUser", "custoLook", "portalLook"])
  },
  components: { MainGlobe },
  methods: {
    quitGame() {
      if (this.currentUser && this.currentUser.email) {
        this.$store.dispatch("quitGame", this.currentUser.playingGame);
      } else {
        this.$store.dispatch("logout");
      }
    }
  }
};
</script>

<style lang="scss">
// MARKETING SECTIONS :
.home-wrapper .section.is-mid {
  position: relative;
  .column {
    z-index: 10;
  }
  padding-top: 3rem;
  padding-bottom: 3rem;
  background: linear-gradient(
    0deg,
    rgba(235, 235, 235, 1) 0%,
    rgba(235, 235, 235, 1) 25%,
    rgba(255, 255, 255, 1) 50%
  );
  @media (max-width: 767px) {
    padding: 1em 0.5em;
  }
}
</style>
