<template>
  <div class="testimonials-wrapper has-text-left">
    <div class="columns is-vcentered is-mobile" v-if="chooseCitation">
      <div class="column is-5 has-text-centered pr-0">
        <img
          :src="chooseCitation.citationLogo"
          :alt="`${chooseCitation.name} - ${chooseCitation.institution} logo`"
          blur="40"
          fit="contain"
        />
      </div>
      <div class="column content pt-0">
        <p class="is-size-5 is-size-6-mobile is-italic">
          "{{ chooseCitation.text }}"
        </p>
        <p>
          <b class="has-text-primary">{{ chooseCitation.name }},</b>
          {{ chooseCitation.job }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "testimonials",
  created() {
    this.chooseCitation = this.allCitations[
      Math.floor(Math.random() * this.allCitations.length)
    ];
  },
  data() {
    return {
      chooseCitation: null,
      allCitations: [
        {
          link: "https://www.efficioconsulting.com/en-gb/",
          institution: "Efficio",
          citationLogo: require("@/assets/marketing/bubble-Efficio.png"),
          citationRank: 5,
          name: "Louis",
          job: "Senior Consultant, Efficio Consulting",
          text:
            "We use the app to educate our consultants. The competitive experience is fun and the lessons learned are applicable for a long time after!"
        },
        {
          link: "https://www.hec.edu/en",
          institution: "HEC Paris",
          citationLogo: require("@/assets/marketing/bubble-HEC.png"),
          citationRank: 1,
          name: "Michel Fender",
          job: "Senior Advisor and Adjunct Professor at HEC Paris",
          text:
            "Zensimu offers valuable advantages. I have used the games several times for in-person or remote trainings - it worked quite well in all cases."
        },
        {
          name: "Giuseppe",
          job:
            "Associate Professor of Procurement & Supply Management, SDA Bocconi",
          type: "university",
          prio: 1,
          text:
            "Zensimu games are an essential part of our MBA and Bachelor courses - gathering several hundreds of simultaneous students.",
          institution: "SDA Bocconi School of Management",
          logoRank: 4,
          citationLogo: require("@/assets/marketing/bubble-Bocconi.png"),
          citationRank: 3,
          link: "https://www.sdabocconi.it/en/home"
        },
        {
          name: "Alessandro",
          job: "Director, Festo Academy",
          type: "industry",
          prio: 1,
          text:
            "Our remote trainings using Zensimu are a success, the experience is very enriching for participants.",
          institution: "Festo",
          logoRank: 7,
          citationLogo: require("@/assets/marketing/bubble-Festo.png"),
          citationRank: 2,
          link:
            "https://www.festocte.it/academy/operations_supply-chain_management/master_operations_supply-chain_management/?mid=A2F9FB96C8594E74A78061CFBC09D3A6"
        },
        {
          name: "Gustavo",
          type: "industry",
          job: "Supply-Chain Manager, Natura",
          prio: 1,
          text:
            "Really great games! We used them to explain supply chain and lean concepts in a few minutes only.",
          institution: "Natura Brasil",
          logoRank: 2,
          citationLogo: require("@/assets/marketing/bubble-Natura.png"),
          citationRank: 4,
          link: "https://www.natura.com/"
        },
        {
          name: "Craig",
          type: "university",
          job: "Professor, University of Connecticut",
          prio: 1,
          text:
            "I use Zensimu games with large classes, it is an extremely valuable solution as a teaching tool!",
          institution: "University of Connecticut",
          logoRank: 6,
          citationLogo: require("@/assets/marketing/bubble-UCONN.png"),
          citationRank: 6,
          link: "https://uconn.edu/"
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped></style>
