<template>
  <div class="login-page columns">
    <div class="column">
      <section class="hero is-fullheight">
        <div class="hero-body">
          <div class="container">
            <div class="mb-5 has-text-centered">
              <a
                target="_blank"
                :href="
                  portalLook
                    ? `https://${portalLook.slug}.zensimu.com/`
                    : zensimuSite
                    ? `https://zensimu.com/`
                    : `https://beergameapp.com/`
                "
              >
                <main-logo
                  class="mb-20"
                  show-title="true"
                  :custo-look="custoLook"
                ></main-logo>
              </a>
            </div>
            <h2 class="title is-2 has-text-centered">
              Log In
            </h2>
            <b-notification
              :active="showError"
              @close="closeError"
              type="is-danger"
              >{{ showError ? error.message : "" }}</b-notification
            >
            <form @submit="submitLogin" novalidate>
              <b-field
                label="Email"
                :type="{ 'is-danger': $v.email.$error }"
                :message="
                  !$v.email.$error
                    ? ''
                    : !$v.email.required
                    ? 'Email is required'
                    : 'Wrong email format'
                "
              >
                <b-input
                  ref="focusinput"
                  type="email"
                  v-model.trim="$v.email.$model"
                  :disabled="loading"
                ></b-input>
              </b-field>
              <b-field
                label="Password"
                v-if="!resetMode"
                :type="{ 'is-danger': $v.password.$error }"
                :message="
                  !$v.password.$error
                    ? ''
                    : !$v.password.required
                    ? 'Password is required'
                    : 'Password must have at least 6 characters'
                "
              >
                <b-input
                  type="password"
                  v-model.trim="$v.password.$model"
                  password-reveal
                  :disabled="loading"
                ></b-input>
              </b-field>
              <b-message type="is-danger" has-icon v-if="warningStudent">
                Please Note The Beergame App is designed to be used by teachers
                ony.
                <br />If you are a student, please ask your referent teacher to
                contact us at <em>hello@zensimu.com</em>.
              </b-message>
              <b-field class="has-text-centered mt-4">
                <button
                  v-if="!resetValidated"
                  type="submit"
                  class="button is-primary is-rounded is-large btn-login"
                  :disabled="loading"
                >
                  <span v-if="!resetMode">Log in</span>
                  <span v-if="resetMode">Send password reset link</span>
                </button>
              </b-field>
              <p class="has-text-success" v-if="resetMode && resetValidated">
                Password reset link sent
                <b-icon icon="check" size="is-small"></b-icon>
              </p>
              <a @click="toggleResetMode">
                <p v-if="!resetMode">Forgot your password ?</p>
                <p v-if="resetMode">
                  <b-icon icon="arrow-left" size="is-small"></b-icon>
                  Back to login
                </p>
              </a>
              <div class="has-text-centered mt-5">
                <router-link to="/signup">
                  Not a member yet ? Sign up
                </router-link>
              </div>
            </form>
            <!-- <div class="is-divider" data-content="OR" v-if="!portalLook"></div>
            <google-sign-in-button
              v-if="!portalLook"
              :optin-updates="true"
              profile-type="education"
            ></google-sign-in-button>
            <em class="has-text-grey" v-if="!portalLook"
              >(Only for existing Google accounts)</em
            > -->
          </div>
        </div>
      </section>
    </div>
    <div
      class="column is-hidden-touch"
      :style="{
        'background-color': custoLook.bckColor,
        color: custoLook.textColor
      }"
    >
      <section class="hero is-fullheight">
        <div class="hero-body">
          <testimonials></testimonials>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required, minLength, email } from "vuelidate/lib/validators";
import Testimonials from "@/components/marketing/Testimonials.vue";

// import GoogleSignInButton from "@/components/GoogleSignInButton.vue";
import MainLogo from "@/components/MainLogo.vue";

export default {
  name: "login",
  components: { MainLogo, Testimonials },
  mounted() {
    this.$refs.focusinput.focus();
  },
  methods: {
    submitLogin(e) {
      e.preventDefault();
      this.$v.$touch();
      let dom = this.email.split("@").pop();
      if (this.portalLook && dom !== this.portalLook.emailDomain) {
        return this.$store.commit("setError", {
          message: "The email domain is invalid"
        });
      } else if (!this.resetMode) {
        if (this.$v.$invalid) {
          this.$store.commit("setError", {
            message: "Please verify the form fields"
          });
        } else {
          this.$store.dispatch("login", {
            email: this.email,
            password: this.password,
            router: this.$router
          });
        }
      } else {
        if (this.$v.email.$invalid) {
          this.$store.commit("setError", {
            message: "Please verify email format."
          });
        } else {
          this.$store
            .dispatch("sendResetPasswordEmail", {
              email: this.email
            })
            .then(resp => {
              if (resp) {
                this.resetValidated = true;
              }
            });
        }
      }
    },
    closeError() {
      this.$store.commit("clearError");
    },
    toggleResetMode() {
      this.resetMode = !this.resetMode;
      this.resetValidated = false;
    }
  },
  computed: {
    ...mapGetters([
      "loading",
      "error",
      "showError",
      "custoLook",
      "portalLook",
      "zensimuSite"
    ]),
    warningStudent() {
      if (this.email.indexOf("@stu") >= 0 || this.email.indexOf("stud") >= 0) {
        return true;
      }
      return false;
    }
  },
  validations: {
    email: {
      required,
      email
    },
    password: {
      required,
      minLength: minLength(6)
    }
  },
  data() {
    return {
      email: "",
      password: "",
      resetMode: false,
      resetValidated: false
    };
  }
};
</script>
<style lang="scss">
.login-page {
  .hero-body {
    padding-top: 1.5rem;
    .container {
      padding: 0 2em;
      @media (max-width: 767px) {
        padding: 0;
      }
    }
  }
  .is-divider::after {
    background-color: $light;
  }
  .hero-head {
    &.hero-body {
      padding-top: 0em;
    }
    h1 {
      margin-bottom: 0.5em;
    }
    li {
      margin-bottom: 0.3em;
    }
    li.has-text-centered {
      margin-bottom: 1.5em;
    }
  }
}
</style>
